import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CtaCard from "../components/cta-card"

const BlogPost = ({ data }) => {
  const post = data.graphCmsPost

  return (
    <Layout>
      <article
        style={{
          maxWidth: "800px",
          margin: "0 auto",
        }}
      >
        <header
          style={{
            marginBottom: `var(--space-5)`,
          }}
        >
          <h1
            style={{
              fontSize: `var(--font-4xl)`,
              marginBottom: `var(--space-3)`,
            }}
          >
            {post.title}
          </h1>
          <p
            style={{
              color: `var(--color-text-tertiary)`,
              fontSize: `var(--font-sm)`,
            }}
          >
            By {post.author.name} •{" "}
            {new Date(post.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
        </header>

        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: post.content.html }}
          style={{
            color: `var(--color-text-secondary)`,
            fontSize: `var(--font-base)`,
            lineHeight: "1.7",
          }}
        />

        <CtaCard />

        {post.keywords && (
          <div
            style={{
              marginTop: `var(--space-5)`,
              paddingTop: `var(--space-4)`,
              borderTop: `1px solid var(--color-border-primary)`,
            }}
          >
            <h3
              style={{
                fontSize: `var(--font-lg)`,
                marginBottom: `var(--space-3)`,
              }}
            >
              Keywords
            </h3>
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: `var(--space-2)`,
                listStyle: "none",
                padding: 0,
                margin: 0,
              }}
            >
              {post.keywords.map(keyword => (
                <li
                  key={keyword}
                  style={{
                    background: `var(--color-bg-tertiary)`,
                    padding: `var(--space-2) var(--space-3)`,
                    borderRadius: `var(--radius-sm)`,
                    fontSize: `var(--font-sm)`,
                    color: `var(--color-text-secondary)`,
                  }}
                >
                  {keyword}
                </li>
              ))}
            </ul>
          </div>
        )}
      </article>
    </Layout>
  )
}

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    graphCmsPost(slug: { eq: $slug }) {
      title
      slug
      date
      excerpt
      content {
        html
      }
      keywords
      author {
        name
        bio
      }
      seo {
        title
        description
        keywords
      }
    }
  }
`

export const Head = ({ data }) => {
  const post = data.graphCmsPost
  return (
    <Seo
      title={post.seo?.title || post.title}
      description={post.seo?.description || post.excerpt}
      keywords={post.seo?.keywords || post.keywords}
    />
  )
}

export default BlogPost
