import React from "react"
import { Link } from "gatsby"

const CtaCard = () => (
  <div
    style={{
      marginTop: `var(--space-5)`,
      padding: `var(--space-4)`,
      backgroundColor: `var(--color-bg-secondary)`,
      borderRadius: `var(--radius-lg)`,
      border: `1px solid var(--color-border-primary)`,
    }}
  >
    <h3
      style={{
        fontSize: `var(--font-xl)`,
        marginBottom: `var(--space-3)`,
        color: `var(--color-text-primary)`,
      }}
    >
      Supercharge Your YouTube Channel
    </h3>
    <p
      style={{
        fontSize: `var(--font-base)`,
        marginBottom: `var(--space-4)`,
        color: `var(--color-text-secondary)`,
      }}
    >
      Explore our AI-powered suite of tools designed to help you create better
      thumbnails, manage campaigns, and gain valuable insights for your YouTube
      channel.
    </p>
    <a
      href="https://yougenie.co/?utm_source=blog"
      target="_blank"
      rel="noopener noreferrer"
      className="button"
      style={{
        display: "inline-block",
      }}
    >
      Try YouGenie Now
    </a>
  </div>
)

export default CtaCard
